<template>
  <div>
    <a-card title="贮存管理">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
                size="small"
                :pagination="pagination"
                :columns="columns"
                :dataSource="items"
                rowKey="id"
                :loading="loading"
                @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal
            v-model="visible"
            :form="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
  import { exportExcel } from "@/utils/excel";
  import { clientExport } from "@/api/export";
  import { clientTemplate, clientImport } from "@/api/import";
  import { keepList, keepDel } from "@/api/refrigerate";

  export default {
    name: "Warehouse",
    components: {
      FormModal: () => import("./FormModal.vue"),
    },
    data() {
      return {
        columns: [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            width: 60,
            customRender: (value, item, index) => {
              return index + 1;
            },
          },
          {
            title: "编号",
            dataIndex: "others.number"
          },
          {
            title: "名称",
            dataIndex: "others.name"
          },
          {
            title: "产品名称",
            dataIndex: "others.product_name"
          },
          {
            title: "储存设备",
            dataIndex: "others.storage_devices"
          },
          {
            title: "存储温度",
            dataIndex: "others.storage_temperature"
          },
          {
            title: "联系人",
            dataIndex: "others.contact"
          },
          {
            title: "手机号",
            dataIndex: "others.phone"
          },
          {
            title: "操作",
            dataIndex: "action",
            scopedSlots: { customRender: "action" },
            width: "156px",
          },
        ],
        pagination: { current: 1, total: 0, pageSize: 10 },
        searchForm: { search: "", page: 1, page_size: 10 },
        loading: false,
        items: [],
        clientsClassificationItems: [],
        visible: false,
        targetItem: { others: {} },
        form: { others: {} },
        importLoading: false,
      };
    },
    computed: {},
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        keepList(this.searchForm)
                .then((data) => {
                  this.pagination.total = data.count;
                  this.items = data.data;
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      create(item) {
        this.list();
      },
      update() {
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      openFormModal(item) {
        this.targetItem = { ...item }
        this.visible = true;
      },
      destroy(id) {
        keepDel({ id: id })
                .then((data) => {
                  this.$message.success("删除成功");
                  this.list();
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      exportExcel() {
        clientExport(this.searchForm)
                .then((resp) => {
                  exportExcel(resp.data, "客户列表");
                })
                .catch((err) => {
                  this.$message.error(err.response.data.error);
                });
      },
      downloadTemplate() {
        clientTemplate()
                .then((resp) => {
                  exportExcel(resp.data, "客户导入模板");
                })
                .catch((err) => {
                  this.$message.error(err.response.data.error);
                });
      },
      importExcel(item) {
        let data = new FormData();
        data.append("file", item.file);
        this.importLoading = true;
        setTimeout(() => {
          clientImport(data)
                  .then(() => {
                    this.$message.success("导入成功");
                    this.list();
                  })
                  .catch((err) => {
                    alert("导入错误:" + "\n" + err.response.data.join("\n"));
                  })
                  .finally(() => {
                    this.importLoading = false;
                  });
        }, 1000);
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  };
</script>
